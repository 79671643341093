import "./referheader.scss"

import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Col, Button } from "react-bootstrap"

const ReferHeader = ({ lang="EN" }) => {
  const pageTitle = "Refer a friend"
  const backgroundImage = "../../images/refer/bg-world@3x.png"

  return (
    <>
          <section className="section-refer">
            <Row>
              <Col className="p-0">
                <div className="div-header">
                  <h1>{pageTitle}</h1>
                  <p>
                    Do you know a talented person you would like to refer to BTS?<br></br>
                    We welcome your recommendation.
                  </p>
                </div>
                <div className="bg-img-grid">
                  <StaticImage
                    className="d-lg-none"
                    layout="fullWidth"
                    alt="bg-world"
                    src={backgroundImage}
                    formats={["auto", "webp", "avif"]}
                  />
                  <div className="div-call bg-img-content">
                    <Button variant="success" href="#referNow">Refer Now <span className="icon-arrow-right"></span></Button>{' '}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="">
              <Col className="col-img" md={6}>
                <StaticImage
                  className="d-xl-none"
                  layout="fullWidth"
                  alt="refer a friend"
                  src="../../images/refer/refer-a-friend@3x.png"
                  formats={["auto", "webp", "avif"]}
                />
              </Col>
              <Col md={6} className="col-text">
                <p>
                  We love diversity! It represents for us the sum of different
                  voices, ideas, and minds, that put together become louder, stronger, and wiser.
                </p>
                <p>
                  At BTS, we believe every individual has the potential to be highly successful;
                  it is not about competing with others, it is about pushing our own limits.
                  Whether you are designing the UX interface for a popular mobile application
                  or developing an application used by millions across the world, we are always
                  looking for talented people to engineer the future with us.
                </p>
                <p>
                  Our people are our main asset and the source of our success. <br></br>
                  If you know people who are creative, autonomous, respectful, team players and hard workers, let us know, we would love to talk to them.
                </p>
              </Col>
            </Row>
          </section>
    </>
  )
}

export default ReferHeader
