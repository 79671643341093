import * as React from "react"
import Layout from "../components/layout/layout"
import Referform from "../components/refer/referform"
import ReferHeader from "../components/refer/referheader"
import Seo from "../components/seo"
// import ReferralGuide from "../components/refer/referralguide"
import { graphql } from "gatsby"
import "./mainpage.scss"

const ReferPage = () => {
  const pageTitle = "Refer a friend"
  const pageDescription = "Do you know a talented person you would like to refer to BTS? We welcome your recommendation."
  const lang = 'EN'
  const meta = []
  const breadcrumbMap = [
    { title: "Careers", url: "/careers/" },
    { title: "Referal Program", url: "#" },
    { title: "Refer to BTS", url: "#referNow" }
  ]

  return (
    <>
      <Layout breadcrumbMap={breadcrumbMap}>
        <div className="max-container-pages">
          <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
          <ReferHeader></ReferHeader>
          {/* <ReferralGuide className="d-none d-md-block d-lg-none"></ReferralGuide> */}
          <Referform id="referNow"></Referform>
        </div>
      </Layout>
    </>
  )
}

export default ReferPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
